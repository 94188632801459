import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetProjectList } from "services/ProjectService";
import {
  apiGetRisk,
  apiUpdateRisk,
  apiCreateRisk,
  apiDeleteRisk,
  apiGetAllRisk,
  apiGetAllFlattenedRisks,
  apiGetAllSummaryRisks
} from "services/RiskService";

import { apiGetProject } from "services/ProjectDetail";

export const getProjectList = createAsyncThunk(
  "projectList/getList",
  async (data) => {
    const response = await apiGetProjectList(data);
    return response.data;
  }
);

export const getProject = createAsyncThunk(
  "projectInfo/getProject",
  async (data) => {
    const response = await apiGetProject(data);
    return response.data;
  }
);

export const fetchAllRisksByCompanyId = createAsyncThunk(
  "riskList/data/fetchAllRisksByCompanyId",
  async (companyId, thunkAPI) => {
    try {
      const response = await apiGetAllRisk(companyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putRisk = createAsyncThunk("riskList/putRisk", async (data) => {
  const response = await apiUpdateRisk(data._id, data);
  return response.data;
});

export const createRisk = createAsyncThunk(
  "riskList/createRisk",
  async (data) => {
    const response = await apiCreateRisk(data);
    return response.data;
  }
);

export const deleteRisk = createAsyncThunk(
  "riskList/deleteRisk",
  async (id) => {
    await apiDeleteRisk(id);
    return id;
  }
);

export const fetchFlattenedRisksByCompanyId = createAsyncThunk(
  "riskListFlatData/data/fetchFlattenedRisksByCompanyId",
  async (companyId, thunkAPI) => {
    try {
      const response = await apiGetAllFlattenedRisks(companyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchRiskSummary = createAsyncThunk(
  "riskSummary/data/fetchRiskSummary",
  async (companyId, thunkAPI) => {
    try {
      const response = await apiGetAllSummaryRisks(companyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const dataSlice = createSlice({
  name: "riskList/data",
  initialState: {
    loading: false,
    riskList: [],
    projectList: [],
    projectInfo: [],
    riskListFlatData: [],
    riskSummary: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRisksByCompanyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllRisksByCompanyId.fulfilled, (state, action) => {
        state.riskList = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllRisksByCompanyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch risks";
      })
      .addCase(putRisk.fulfilled, (state, action) => {
        state.riskList = state.riskList.map((risk) =>
          risk._id === action.payload._id ? action.payload : risk
        );
      })
      .addCase(createRisk.fulfilled, (state, action) => {
        state.riskList.push(action.payload);
      })
      .addCase(deleteRisk.fulfilled, (state, action) => {
        state.riskList = state.riskList.filter(
          (risk) => risk._id !== action.payload
        );
      })
      .addCase(getProjectList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action) => {
        state.projectList = action.payload;
        state.loading = false;
      })
      .addCase(getProjectList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch projects";
      })
      .addCase(getProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.projectInfo = action.payload;
        state.loading = false;
      })
      .addCase(getProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch project";
      })
      .addCase(fetchFlattenedRisksByCompanyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFlattenedRisksByCompanyId.fulfilled, (state, action) => {
        state.riskListFlatData = action.payload;
        state.loading = false;
      })
      .addCase(fetchFlattenedRisksByCompanyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch flattened risks";
      })
      .addCase(fetchRiskSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRiskSummary.fulfilled, (state, action) => {
        state.riskSummary = action.payload;
        state.loading = false;
      })
      .addCase(fetchRiskSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch risk summary";
      });
  }
});

export default dataSlice.reducer;
