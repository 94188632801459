// services/CompanyService.js

import ApiService from './ApiService'; 

// Create Company 
export async function createCompany(data) {
    return ApiService.fetchData({
        url: `/company`,
        method: 'post',
        data
    });
}



// Read Company 
export async function getCompany(companyId) {
    const response = await ApiService.fetchData({
        url: `/companyconfig/${companyId}`,
        method: 'get',
    });
    console.log("Company Data Response:", response); // Log the full response
    return response;
}



// Update Permissions Company 
export async function updateCompany(formData) {
    return ApiService.fetchData({
        url: `/companyconfig/${formData.id}`,
        method: 'put',
        data: { permissions: formData.permissions }
    });
}

// Delete Company 
export async function deleteCompany(companyId) {
    return ApiService.fetchData({
        url: `/companyconfig/${companyId}`,
        method: 'delete'
    });
}



/// Update Project 
export async function apiPutCompanyTemplate(formData) {
    return ApiService.fetchData({
        url: `/companyconfig/${formData.id}/updatetemplate`,
        method: 'put',
        data: formData
    })
}

/// Update Project 
export async function apiPutCompanyDropdowns (formData) {
    return ApiService.fetchData({
        url: `/companyconfig/${formData.id}/updatedropdowns`,
        method: 'put',
        data: formData
    })
}



