import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUpdateUser } from '../../services/AuthService'; // Replace with your actual API service

// Initial state
export const initialState = {
    _id: '',
    company: null, 
    avatar: '',
    firstname: '',
    lastname: '',
    userName: '',
    email: '',
    authority: []
};

// Thunk for updating user via API
export const apiCallUpdateUser = createAsyncThunk(
    'auth/user/apiUpdateUser',
    async ({ userId, userData }, { rejectWithValue }) => {
        try {
            const response = await apiUpdateUser(userId, userData); // Replace with your actual API call
            return response.data; // Expecting the server to return an object with a `user` field
        } catch (error) {
            console.error("Failed to update user:", error);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Slice
export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => {  
            return { ...state, ...action.payload };
        },
        userLoggedOut: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(apiCallUpdateUser.fulfilled, (state, action) => {
                // Assuming response structure is { message: '...', user: {...} }
                return { ...state, ...action.payload.user };
            })
            .addCase(apiCallUpdateUser.rejected, (state, action) => {
                console.error('Failed to update user:', action.payload);
            });
    }
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
