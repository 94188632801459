import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';

const rootReducer = (asyncReducers) => (state, action) => {
    if (action.type === 'RESET_APP_STATE') {
        state = undefined; 
    }
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        ...asyncReducers,
    });

    return combinedReducer(state, action);
};

export default rootReducer;
