const appConfig = {
    apiPrefix: 'https://api.pmodata.app',
    //apiPrefix: 'http://localhost',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/onboard',
    enableMock: false

    
}



export default appConfig



