import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState as userInitialState } from 'store/auth/userSlice';
import { apiSignIn, apiSignOut } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import { getCompany,setCompany, clearCompany } from 'store/auth/companySlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';

function useAuth() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useQuery();
    const { token, signedIn } = useSelector((state) => state.auth.session);

    const signIn = async ({ username, password }) => {
        try {
            const resp = await apiSignIn({ username, password });
            if (resp.data) {
                const { token } = resp.data;
                dispatch(onSignInSuccess(token));
                if (resp.data.user) {
                    await dispatch(setUser(resp.data.user));
                    await dispatch(setCompany(resp.data.company));
          
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };

    const handleSignOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(userInitialState));
        // Dispatch clearCompany to reset the company state
        dispatch(clearCompany());
        dispatch({ type: 'RESET_APP_STATE' });  
        navigate(appConfig.unAuthenticatedEntryPath);
    };

    const signOut = async () => {
        try {
            await apiSignOut();
            handleSignOut();
        } catch (errors) {
            handleSignOut();
        }
    };

    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
    };
}

export default useAuth;
