import { forwardRef, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useSegment } from './context';
import { CONTROL_SIZES, SIZES } from '../utils/constant';
import SegmentContext from './context';
import PropTypes from 'prop-types';

const unwrapArray = (arg) => (Array.isArray(arg) ? arg[0] : arg);

const SegmentItem = forwardRef(
  ({ value: valueProp, children, className, disabled, ...rest }, ref) => {
    const { size } = useContext(SegmentContext);
    const {
      value: valueContext,
      onActive,
      onDeactivate,
      selectionType,
    } = useSegment();
    
    const valuePropStr = String(valueProp); // Convert value to string
    const active = valueContext.includes(valuePropStr);

    const getSegmentSize = useCallback(() => {
      let sizeClass = '';
      switch (size) {
        case SIZES.LG:
          sizeClass = 'h-12 md:px-8 py-2 px-4 text-base';
          break;
        case SIZES.SM:
          sizeClass = 'h-8 px-3 py-2 text-sm';
          break;
        case SIZES.XS:
          sizeClass = 'h-6 px-3 py-1 text-xs';
          break;
        default:
          sizeClass = 'h-10 md:px-8 py-2 px-4';
          break;
      }
      return sizeClass;
    }, [size]);

    const getColorClasses = useCallback((value, isActive, isDisabled) => {
      let baseClass = '';
      let activeClass = '';
      let disabledClass = '';

      switch (value) {
        case '0':
          baseClass = ' ml-1  bg-white border  hover:bg-gray-100 rounded-md ';
          activeClass = ' ml-1 bg-black text-white hover:bg-gray-600 rounded-md';
          disabledClass = 'ml-1  py-0 bg-white  hover:bg-gray-100 rounded-md';
          break;
        case '1':
          baseClass = ' ml-1  bg-white border hover:bg-green-500  hover:text-white rounded-md';
          activeClass = ' ml-1  bg-green-400 text-white text-bold  hover:bg-green-500 hover:text-white rounded-md ';
          disabledClass = ' ml-1  bg-white border  hover:bg-green-500 rounded-md';
          break;
        case '2':
          baseClass = 'ml-1  bg-white border hover:bg-yellow-400   hover:text-white rounded-md';
          activeClass = ' ml-1 bg-yellow-400 text-white text-bold  hover:bg-yellow-400  hover:text-white rounded-md';
          disabledClass = ' ml-1 bg-white border bg-yellow-400 hover:bg-yellow-400 rounded-md';
          break;
        case '3':
          baseClass = 'ml-1  bg-white border   hover:bg-orange-500   hover:text-white rounded-md';
          activeClass = ' ml-1  bg-orange-400 text-white text-bold  hover:bg-orange-500 rounded-md ';
          disabledClass = 'ml-1  bg-white border bg-orange-400  hover:bg-orange-500 rounded-md';
          break;
        case '4':
          baseClass = 'ml-1  bg-white border  hover:bg-red-500 hover:text-white  hover:text-white rounded-md ';
          activeClass = 'ml-1  bg-red-400 text-white text-bold  hover:bg-red-500 rounded-md ';
          disabledClass = 'ml-1 bg-white border bg-red-400   hover:bg-red-500 rounded-md';
          break;
        case '5':
          baseClass = 'ml-1  text-red-600 text-bold bg-white border  hover:bg-red-700 hover:text-white  rounded-md ';
          activeClass = 'ml-1 bg-red-600  text-white text-bold hover:bg-red-700  rounded-md';
          disabledClass = 'ml-1  text-red-600 text-bold bg-white border bg-red-700   hover:bg-red-700 hover:text-white rounded-md  ';
          break;

          // Opportunity

          case '-1':
            baseClass = ' ml-1  bg-white border hover:bg-blue-200  hover:text-white rounded-md';
            activeClass = ' ml-1  bg-blue-200 text-white text-bold  hover:bg-blue-200 hover:text-white rounded-md ';
            disabledClass = ' ml-1  bg-white border  hover:bg-blue-200 rounded-md';
            break;
          case '-2':
            baseClass = 'ml-1  bg-white border hover:bg-blue-300   hover:text-white rounded-md';
            activeClass = ' ml-1 bg-blue-300 text-white text-bold  hover:bg-blue-300  hover:text-white rounded-md';
            disabledClass = ' ml-1 bg-white border bg-blue-300 hover:bg-blue-300 rounded-md';
            break;
          case '-3':
            baseClass = 'ml-1  bg-white border   hover:bg-blue-500   hover:text-white rounded-md';
            activeClass = ' ml-1  bg-blue-500 text-white text-bold  hover:bg-blue-500 rounded-md ';
            disabledClass = 'ml-1  bg-white border bg-blue-500  hover:bg-blue-500 rounded-md';
            break;
          case '-4':
            baseClass = 'ml-1  bg-white border  hover:bg-blue-700 hover:text-white  hover:text-white rounded-md ';
            activeClass = 'ml-1  bg-blue-700 text-white text-bold  hover:bg-blue-700 rounded-md ';
            disabledClass = 'ml-1 bg-white border bg-blue-700   hover:bg-blue-700 rounded-md';
            break;
          case '-5':
            baseClass = 'ml-1  text-blue-900 text-bold bg-white border  hover:bg-blue-900 hover:text-white  rounded-md ';
            activeClass = 'ml-1 bg-blue-900  text-white text-bold hover:bg-blue-900  rounded-md';
            disabledClass = 'ml-1  text-blue-900 text-bold bg-white border bg-blue-900   hover:bg-blue-900 hover:text-white rounded-md  ';
            break;
        default:
          baseClass = 'bg-gray-400 hover:bg-gray-500 rounded-md';
          activeClass = 'border-gray-400 rounded-md';

          break;
      }

      let classString = isActive ? activeClass : baseClass;
      if (isDisabled) {
        classString += ` ${disabledClass}`;
      }
      return classString;
    }, []);

    const onSegmentItemClick = () => {
      if (!disabled) {
        if (!active) {
          if (selectionType === 'single') {
            onActive([valuePropStr]);
          } else if (selectionType === 'multiple') {
            const nextValue = [...valueContext, valuePropStr];
            onActive(nextValue);
          }
        } else if (selectionType === 'multiple') {
          onDeactivate(valuePropStr);
        }
      }
    };

    return typeof children === 'function' ? (
      unwrapArray(children)({
        ref,
        active,
        onSegmentItemClick,
        disabled,
        value: valuePropStr,
        ...rest,
      })
    ) : (
      <button
        className={classNames(
          // 'segment-item segment-item-default',
          active && 'segment-item-active',
          getColorClasses(valuePropStr, active),
          getSegmentSize(),
          className
        )}
        onClick={onSegmentItemClick}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

SegmentItem.defaultProps = {
  disabled: false,
};

SegmentItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SegmentItem;
