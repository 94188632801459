import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCompany as apiCreateCompany,
  getCompany as apiGetCompany,
  updateCompany as apiUpdateCompany,
  deleteCompany as apiDeleteCompany,
  apiPutCompanyDropdowns
} from 'services/CompanyService';




// Async thunk for creating a company
export const createCompany = createAsyncThunk(
  'company/createCompany',
  async (data, thunkAPI) => {
    try {
      const response = await apiCreateCompany(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for reading company settings
export const getCompany = createAsyncThunk(
  'company/getCompany',
  async (companyId, thunkAPI) => {
    try {
      const response = await apiGetCompany(companyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for updating company settings
export const updateCompany = createAsyncThunk(
  'company/updateCompany',
  async ({ companyId, permissions }, thunkAPI) => {
    try {
      const response = await apiUpdateCompany({ id: companyId, permissions });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


// Async thunk for updating company settings
export const updateDropdowns = createAsyncThunk(
  'company/updateCompanyDropdowns',
  async ({ companyId, dropdowns }, thunkAPI) => {
      try {
          const response = await apiPutCompanyDropdowns({ id: companyId, dropdowns });
          console.log('API Response:', response.data); // Log the response to inspect it
          return response.data; // Assuming response.data contains the updated company object
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data);
      }
  }
);





// Async thunk for deleting a company
export const deleteCompany = createAsyncThunk(
  'company/deleteCompany',
  async (companyId, thunkAPI) => {
    try {
      const response = await apiDeleteCompany(companyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setCompany: (state, action) => {
      state.company = {
        ...action.payload,
        permissions: action.payload.permissions || {},
        dropdowns: action.payload.dropdowns || []
      };
    },
    clearCompany: (state) => {
      state.company = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Company
      .addCase(createCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.company = {
          ...action.payload,
          permissions: action.payload.permissions || {},
          dropdowns: action.payload.dropdowns || []
        };
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Read Company
      .addCase(getCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.company = {
          ...action.payload,
          permissions: action.payload.permissions || {},
          dropdowns: action.payload.dropdowns || []
        };
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Update Company
      .addCase(updateCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.company = {
          ...action.payload,
          permissions: action.payload.permissions || {},
          dropdowns: action.payload.dropdowns || []
        };
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Update Company Dropdowns
      .addCase(updateDropdowns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDropdowns.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Ensure the response is properly structured
        if (state.company && action.payload.data) {
            state.company.dropdowns = action.payload.data.dropdowns; // Access dropdowns from the correct part of the response
        } else {
            console.error('Dropdowns update failed: Incorrect response structure', action.payload);
        }
    })
      .addCase(updateDropdowns.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Delete Company
      .addCase(deleteCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCompany.fulfilled, (state) => {
        state.status = 'succeeded';
        state.company = null;
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});


export const { setCompany, clearCompany } = companySlice.actions;

export default companySlice.reducer;
