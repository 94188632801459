import ApiService from './ApiService';

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/auth',
        method: 'post',
        data
    });
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/users',
        method: 'post',
        data
    });
}


export async function apiSignUpInvite(data) {
    return ApiService.fetchData({
        url: '/users/verify-email',
        method: 'post',
        data
    });
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/auth/logout',
        method: 'post',
        data
    });
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/users/forgot-password',
        method: 'post',
        data
    });
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/users/reset-password',
        method: 'post',
        data
    });
}

// Get all users from a specific company
export async function apiGetCompanyUsers(companyId) {
    return ApiService.fetchData({
        url: `/users/company/${companyId}`,
        method: 'get',
    });
}

// Invite a new user
export async function apiInviteUser(data) {
    return ApiService.fetchData({
        url: '/users/invite',
        method: 'post',
        data
    });
}


//import { apiUpdateUser } from "../../../services/AuthService"; //
// Update an existing user
export async function apiUpdateUser(userId, data) {
    return ApiService.fetchData({
        url: `/users/${userId}`,
        method: 'put',
        data
    });
}



// Delete an existing user
export async function apiDeleteUser(userId) {
    return ApiService.fetchData({
        url: `/users/${userId}`,
        method: 'delete',
    });
}

// Send a password reset email to a user
export async function apiSendResetPasswordEmail(data) {
    return ApiService.fetchData({
        url: '/users/reset-password',
        method: 'post',
        data
    });
}

// Re-send invite to a user
export async function apiResendInvite(userId) {
    return ApiService.fetchData({
        url: `/users/resend-invite/${userId}`,
        method: 'get'
    });
}
