import ApiService from "./ApiService";

export async function apiGetProjectDashboardData(params) {
    return ApiService.fetchData({
        url: '/project/dashboard',
        method: 'get',
        params
    });
}

/// Get Projects For Company 
export async function apiGetProjectList(data) {
    return ApiService.fetchData({
        url: `/company/projects/${data}`,
        method: 'get',
        data
    });
}

/// Create New Project 
export async function apiNewProject({ companyID, data }) {
    return ApiService.fetchData({
        url: `/company/projects/${companyID}`,
        method: 'post',
        data: data
    });
}

/// Update Existing Project
export async function apiPutProjectList(data, projectID) {
    return ApiService.fetchData({
        url: `/project/${projectID}`,
        method: 'put',
        body: JSON.stringify(data)
    });
}

/// Delete Project
export async function apiDeleteProject(projectID) {
    return ApiService.fetchData({
        url: `/company/project/${projectID}`,
        method: 'delete'
    });
}
