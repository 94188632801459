import React from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import CloseButton from '../CloseButton';
import { motion } from 'framer-motion';

const FullScreenDrawer = props => {
	const {
		children,
		className,
		closable,
		isOpen,
		onClose,
		closeTimeoutMS,
		placement = 'right', // default placement
		bodyOpenClassName,
		portalClassName,
		overlayClassName,
		title,
		footer,
		headerClass,
		footerClass,
		bodyClass,
		showBackdrop,
		backgroundColor = '#ffffff',
		...rest
	} = props;

	const onCloseClick = e => {
		onClose(e);
	};

	const renderCloseButton = <CloseButton onClick={onCloseClick} />;

	// Style for fullscreen
	const getStyle = () => ({
		dimensionClass: 'fullscreen',
		contentStyle: { width: '100vw', height: '100vh' , background: backgroundColor }
	});

	const { dimensionClass, contentStyle } = getStyle();

	return (
		<Modal
			className={{
				base: classNames('drawer', className),
				afterOpen: 'drawer-after-open',
				beforeClose: 'drawer-before-close'
			}}
			overlayClassName={{
				base: classNames('drawer-overlay', overlayClassName, !showBackdrop && 'bg-transparent'),
				afterOpen: 'drawer-overlay-after-open',
				beforeClose: 'drawer-overlay-before-close'
			}}
			portalClassName={classNames('drawer-portal', portalClassName)}
			bodyOpenClassName={classNames('drawer-open', 'drawer-lock-scroll', bodyOpenClassName)}
			ariaHideApp={false}
			isOpen={isOpen}
			closeTimeoutMS={closeTimeoutMS}
			{...rest}
		>
			<motion.div
				className={classNames('drawer-content', dimensionClass)}
				style={contentStyle}
				initial={{ opacity: 0 }}
				animate={{ opacity: isOpen ? 1 : 0 }}
			>
				{title || closable ? (
					<div className={classNames('drawer-header', headerClass)}>
						{typeof title === 'string' ? <h4>{title}</h4> : <span>{title}</span>}
						{closable && renderCloseButton}
					</div>
				) : null}
				<div className={classNames('drawer-body', bodyClass)}>
					{children}
				</div>
				{footer && <div className={classNames('drawer-footer', footerClass)}>{footer}</div>}
			</motion.div>
		</Modal>
	);
};

FullScreenDrawer.propTypes = {
	closable: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	headerClass: PropTypes.string,
	footerClass: PropTypes.string,
	bodyClass: PropTypes.string,
	showBackdrop: PropTypes.bool,
	bodyOpenClassName: PropTypes.string,
	portalClassName: PropTypes.string,
	overlayClassName: PropTypes.string,
	backgroundColor: PropTypes.string 
};

FullScreenDrawer.defaultProps = {
	closable: true,
	closeTimeoutMS: 300,
	showBackdrop: true,
	backgroundColor: '#ffffff'
};

export default FullScreenDrawer;
