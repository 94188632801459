import ApiService from "./ApiService";

export async function apiCreateRisk(formData) {
    return ApiService.fetchData({
        url: `/risks`,
        method: 'post',
        data: formData
    });
}


export async function apiGetAllRisk(id) {
    return ApiService.fetchData({
        url: `/risks/company/${id}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export async function apiGetRisk(id) {
    return ApiService.fetchData({
        url: `/risks/${id}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export async function apiUpdateRisk(id, formData) {
    return ApiService.fetchData({
        url: `/risks/${id}`,
        method: 'put',
        data: formData,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export async function apiDeleteRisk(id) {
    return ApiService.fetchData({
        url: `/risks/${id}`,
        method: 'delete',
    });
}



// New service for fetching flattened risks
export async function apiGetAllFlattenedRisks(id) {
    return ApiService.fetchData({
        url: `/risks/company/datawarehouse/${id}`,
        method: 'get',
    });
}


// New service for fetching flattened risks
export async function apiGetAllSummaryRisks(id) {
    return ApiService.fetchData({
        url: `/risks/company/datawarehousesummary/${id}`,
        method: 'get',
    });
}
