import { combineReducers } from '@reduxjs/toolkit';
import session from './sessionSlice';
import user from './userSlice';
import company from './companySlice'; 
import riskList from '../../views/Risks/store/dataSlice';



const reducer = combineReducers({
    session,
    user,
    company,
    riskList
});



export default reducer;
