import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';


import './locales';

// Mapbox and FilePond CSS imports
import "mapbox-gl/dist/mapbox-gl.css";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'react-edit-text/dist/index.css';

// Syncfusion CSS imports for version 23.1.39
import '@syncfusion/ej2-base/styles/tailwind.css';  
import '@syncfusion/ej2-buttons/styles/tailwind.css';  
import '@syncfusion/ej2-calendars/styles/tailwind.css';  
import '@syncfusion/ej2-dropdowns/styles/tailwind.css';
import '@syncfusion/ej2-grids/styles/tailwind.css';
import '@syncfusion/ej2-lists/styles/tailwind.css';
import '@syncfusion/ej2-inputs/styles/tailwind.css';  
import '@syncfusion/ej2-navigations/styles/tailwind.css';
import '@syncfusion/ej2-react-pivotview/styles/tailwind.css'
import '@syncfusion/ej2-popups/styles/tailwind.css';
import '@syncfusion/ej2-splitbuttons/styles/tailwind.css';
import '@syncfusion/ej2-notifications/styles/tailwind.css';
import '@syncfusion/ej2-gantt/styles/tailwind.css';
import '@syncfusion/ej2-layouts/styles/tailwind.css';
import '@syncfusion/ej2-treegrid/styles/tailwind.css';
import '@syncfusion/ej2-richtexteditor/styles/tailwind.css';
import '@syncfusion/ej2-react-grids/styles/tailwind.css';
import '@syncfusion/ej2-react-treegrid/styles/tailwind.css';
import '@syncfusion/ej2-react-kanban/styles/tailwind.css';
import '@syncfusion/ej2-react-inplace-editor/styles/tailwind.css';
import '@syncfusion/ej2-react-richtexteditor/styles/tailwind.css';
import '@syncfusion/ej2-react-filemanager/styles/tailwind.css';
import '@syncfusion/ej2-icons/styles/tailwind.css';

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter history={history}>
						<Theme>
							<Layout />
						</Theme>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	);
}

export default App;
