import ApiService from "./ApiService"



/// Get Single Project 
export async function apiGetProject(data) {
    return ApiService.fetchData({
        url: `/company/project/${data}`,
        method: 'get',
        data
    })
}





///Get Project Products
export async function apiGetProjectProducts(data) {
    return ApiService.fetchData({
        url: `company/project/${data}/products/`,
        method: 'get',
        data
    })
}





///Get Project Chat Messages
export async function apiGetProjectMessages(data) {
    return ApiService.fetchData({
        url: `company/project/${data}/messages/`,
        method: 'get',
        data
    })
}






/// Update Project 
export async function apiPutProject (formData) {
    return ApiService.fetchData({
        url: `/company/project/${formData.id}`,
        method: 'put',
        data: formData
    })
}


/// Update Project 
export async function apiPutProjectTemplate (formData) {
    return ApiService.fetchData({
        url: `/company/project/${formData.id}/updatetemplate`,
        method: 'put',
        data: formData
    })
}

/// Update Project 
export async function apiPutProjectDropdowns (formData) {
    return ApiService.fetchData({
        url: `/company/project/${formData.id}/updatedropdowns`,
        method: 'put',
        data: formData
    })
}



export async function apiGetProjectMembers () {
    return ApiService.fetchData({
        url: '/project/scrum-board/members',
        method: 'post',
    })
}

